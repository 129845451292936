import { template as template_f5e99d3666684ea88f19462cbfc1ab6b } from "@ember/template-compiler";
const SidebarSectionMessage = template_f5e99d3666684ea88f19462cbfc1ab6b(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
