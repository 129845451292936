import { template as template_41fa9af4f9594aa48bba6aefcb0872e3 } from "@ember/template-compiler";
const FKControlMenuContainer = template_41fa9af4f9594aa48bba6aefcb0872e3(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
