import { template as template_3cd479c204a843a68a14ecbc142a50d7 } from "@ember/template-compiler";
const WelcomeHeader = template_3cd479c204a843a68a14ecbc142a50d7(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
