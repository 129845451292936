import { template as template_edf0adee267b47f29bb4376c1e1d9068 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_edf0adee267b47f29bb4376c1e1d9068(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
