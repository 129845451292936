import { template as template_f2870db9d81b47e7ab1d7b23f4829fa6 } from "@ember/template-compiler";
const FKLabel = template_f2870db9d81b47e7ab1d7b23f4829fa6(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
